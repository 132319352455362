import React from 'react'
import {useState, useRef, useEffect} from 'react'
import './Home.css'

import FloatingMessageInput from '../../Components/FloatingMessageInput/FloatingMessageInput';
import FloatingDonation from '../../Components/FloatingDonation/FloatingDonation';
import sendMessageToChatGPT from '../../Api';

const Home = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [donation, setDonation] = useState(false);
    const messagesEndRef = useRef(null)

    const handleSend = (message) => {
        const newMessage = { role: "user", content: message };
        const updatedMessages = [...messages, newMessage];
        setMessages(updatedMessages);
        sendMessageToChatGPT(updatedMessages, (responseText) => {
            setMessages(prevMessages => [...prevMessages, {role:"assistant", content:responseText}])
        }, setLoading)
    }

    useEffect(() => {
        return messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [messages])

    const handleDonation = (e) => {
        e.preventDefault()
        setDonation(!donation)
    }
    const handleCloseDonation = () => {
        setDonation(false)
    }

    return(
        <div className='chatContainer'>
            <div className='scrollContainer'>
                <div style={{height:"50px"}}> </div>
                <div className='messagesContainer'>
                    {
                        messages.map((msg, index) => (
                            <div key={index} className={msg.role === "user" ? 'userMessage' : 'asistantMessage' }>
                                <div dangerouslySetInnerHTML={{ __html: msg.content }} />
                            </div>
                        ))
                    }
                    {loading && <div className='loading'>
                        <div className="typing-indicator">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>}
                    <div ref={messagesEndRef}/>
                </div>
            </div>
            <FloatingMessageInput onSend={handleSend} onDonate={handleDonation}/>
            <FloatingDonation visible={donation} onClose={handleCloseDonation} />
        </div>
    )
}

export default Home;

