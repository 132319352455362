import React from 'react'
import {useState, useRef, useEffect} from 'react'
import heart from '../../Assets/heart.svg'
import './FloatingMessageInput.css'
import arrow from '../../Assets/arrowUp.svg'


const FloatingMessageInput = ({onSend, onDonate}) => {
    const [message, setMessage] = useState('')
    const refTextArea = useRef(null)

    const handleSend = (e) => {
        e.preventDefault()
        if (message.trim()){
            onSend(message)
            setMessage('')
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey){
            e.preventDefault()
            handleSend(e)
        }
    }

    const adjustHeight = () =>{
        if(refTextArea.current){
            refTextArea.current.style.height = "auto"
            refTextArea.current.style.height = `${refTextArea.current.scrollHeight}px`
        }
    }

    useEffect(() => {
        adjustHeight();
    }, [message])

    return (
        <form className='container'>
            <div className='container2'>
            <button className='sendButton' onClick={onDonate}>
                <img src={heart} alt='Heart' height='30px' className='imgFilter'/>
            </button>
            <textarea
            rows = "1"
            ref = {refTextArea}
            className='textInput'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder='Message Alice'/>
            <button className='sendButton' style={{width:"35px", height:"35px"}} onClick={handleSend}>
                <img src={arrow} alt='Send messages' height="25px" className='imgFilter'/>
            </button>
            </div>
        </form>
    )
}

export default FloatingMessageInput;