import React, {useRef, useEffect} from 'react';
import './FloatingDonation.css'; // Import CSS for styling
import trakteer from '../../Assets/trakteer.png'
import paypal from '../../Assets/paypal.png'

const FloatingDonation = ({ visible, onClose }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!visible) return null;

  return (
    <div className="floating-container">
      <div className='floating-container2' ref={containerRef}>
      <button className="close-button" onClick={onClose}>x</button>
      <h2 className='donateTitle'>Support Our Anonymous AI Experience</h2>
      <p><span className='highlight'>Get stuck? Unstuck it here!</span><br/>We offer advanced AI interactions with complete anonymity, ensuring your privacy while you explore our features. Your support helps cover the costs of the ChatGPT API, allowing us to maintain this secure and innovative service. <br/> <span className='thanks'>Thank You for your essential support! <span role="img" aria-label='Heart'>💚</span></span></p>
      <div className="links">
        <a href="https://trakteer.id/ngestuck" target="_blank" rel="noopener noreferrer">
          <img src={trakteer} alt="trakteer" />
        </a>
        <a href="https://paypal.me/catarinaclaes" target="_blank" rel="noopener noreferrer">
          <img src={paypal} alt="stripe" />
        </a>
        {/* Add more links as needed */}
      </div>
      </div>
    </div>
  );
};

export default FloatingDonation;
